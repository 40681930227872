import React, { useState, useContext } from 'react';

import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SessionsTable from '../SessionsTable';

import { GameContentContext } from '../../helpers/GameContentProvider';
import './WordquestTab.css';
import ScenePopup from './ScenePopup';

const WordquestTab = ({data}) => {
    const [modalState, setModalState] = useState("close");
    const { gameContent } = useContext(GameContentContext);

    const handleModalState = (key) => {
      setModalState(key);
    }
  
    const handleModalClose = () => {
      setModalState("close");
    }

    function getSessions(){
        let session_list = [];
        if (data.wordQuest && data.wordQuest.gameSessions){
            let raw_sessions = data.wordQuest.gameSessions;
            raw_sessions.map(s => {

                let scenes_seen = new Set();
                let quest_words = new Set();
                let words_learned = [];
                if (s.current_scene_name){
                    scenes_seen.add(s.current_scene_name);
                }
                if (s.current_quest_word){
                    quest_words.add(s.current_quest_word);
                }
                if (s.finished_quests){
                    s.finished_quests.map(q => {
                        scenes_seen.add(q[0])
                        quest_words.add(q[1])
                    })
                }
                if (s.wordsLearned){
                    s.wordsLearned.map(w => {
                        let split_word = w.split("_");
                        words_learned.push(split_word[split_word.length-1]);
                    });
                }

                let datetime = "N/A";
                if(s.end_timestamp){
                    let timestamp = s.end_timestamp.toString().slice(0, 10).split("-")
                    datetime = timestamp[1] + "/" + timestamp[2] + "/" + timestamp[0]
                }
                let scenes = Array.from(scenes_seen).join(", ") || "N/A";
                let missions = Array.from(quest_words).join(", ") || "N/A";
                let words = words_learned.join(", ") || "N/A";

                session_list.push({
                    "date": datetime,
                    "start": "",
                    "end": "",
                    "properties":[scenes, missions, words]
                });
            });
        }
        return session_list;
    }

    function getWQContent() {
        let wq_master = {};
        let wq_content = gameContent.wordquest;
        wq_content.topics.map(t => {
            let scenes_array = t.scene.split(":");
            for (let s of scenes_array){
                if (!Object.keys(wq_master).includes(s)){
                    wq_master[s] = {by_mission: {}, by_word: {}};
                }
                wq_master[s].by_mission[t.topic] = [];
            }
        });
        wq_content.words.map(w => {
            let categories = w.topic.split(":");
            categories.map(c => {
                let c_split = c.split("_");
                let scene_word = c_split[0];
                let mission_word = c_split[1];
                if(!Object.keys(wq_master[scene_word].by_mission).includes(mission_word)){
                    wq_master[scene_word].by_mission[mission_word] = [];
                }
                if(!Object.keys(wq_master[scene_word].by_word).includes(w.item)){
                    wq_master[scene_word].by_word[w.item] = [];
                }
                wq_master[scene_word].by_mission[mission_word].push(w.item);
                wq_master[scene_word].by_word[w.item].push(mission_word);
            });
        });
        console.log(wq_master);
        return wq_master;
    }

    function createScenePopups(content, sess) {
        let scene_blocks = [];
        let scene_popups = [];
        Object.keys(content).map(scene => {
            let image = "";
            try {
              image = require(`./assets/${scene}_2.jpg`);
            } catch {
              image = "";
            }

            scene_blocks.push(
                <div className="scene-block" onClick={() => handleModalState(scene)} style={{
                    backgroundImage: `url('${image}')`,
                    backgroundSize:'cover',
                }}>
                    <div className="scene-block-title">{scene}</div>
                </div>
            )
            let progress = (data && data.wordQuest && data.wordQuest.progress) && data.wordQuest.progress[scene] || null;
            let sessions_by_scene = sess.filter((item) => {
                return item.properties[0].includes(scene);
            });
            scene_popups.push(
                <ScenePopup open={modalState === scene} handleClose={handleModalClose} name={scene} data={content[scene]} progress={progress} sessions={sessions_by_scene}/>
            )
        })
        return {blocks: scene_blocks, popups: scene_popups};
    }

    let all_content = getWQContent();
    let sessions = getSessions();
    let created_scenes = createScenePopups(all_content, sessions);
    let scene_divs = created_scenes.blocks;
    let scene_popups = created_scenes.popups;

    return (
        <div className="wordquest-tab-wrap">
            <div className="scenes-wrap">
                <div className="popup-subheader"><ImageSearchIcon id="icon"/> <b>Scenes</b></div>
                <div className="scenes-contain">
                    {scene_divs}
                </div>
                {scene_popups}
            </div>

            <div className="table-wrap">
                <SessionsTable icon={<EqualizerIcon id="icon"/>} title="Sessions"
                    heads={["Scenes Practiced", "Quests Completed", "Words Learned"]}
                    sessions={sessions}
                    show_duration={false}
                />
            </div>
        </div>
    )
}

export default WordquestTab;