import React, { useState, createContext, useMemo } from 'react';

export const GameContentContext = createContext({});

const GameContentProvider = ({ children }) => {
  const [gameContent, setGameContent] = useState({});

  const gameContentProviderValue = useMemo(() => ({ gameContent, setGameContent }), [
    gameContent,
    setGameContent,
  ]);

  return (
    <GameContentContext.Provider value={gameContentProviderValue}>
      {children}
    </GameContentContext.Provider>
  );
};

export default GameContentProvider;
