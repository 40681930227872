import React from 'react';

const Statistics = ({data, books}) => {
  let statistics = [0, 0, 0];

  const setStats = () => {
    for (let book in data.gameInfo) {
      statistics[2]++;
    }
    for (let book in books) {
      statistics[0] += books[book].exposed.length;
      statistics[1] += books[book].learned.length;
    }
  };

  setStats();

  return(
    <div className="stats">
        <div className="stat">
          <div className="stat_num">
            {statistics[0]}
          </div>
          <div>words exposed</div>
        </div>
        <div className="stat">
          <div className="stat_num">
            {statistics[1]}
          </div>
          <div>words learned</div>
        </div>
        <div className="stat">
          <div className="stat_num">
            {statistics[2]}
          </div>
          <div>books opened</div>
        </div>
      </div>
  )
}

export default Statistics;
