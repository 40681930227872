import React from 'react';
import Modal from "@material-ui/core/Modal";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";

import AssessmentIcon from "@material-ui/icons/Assessment";
import CloseIcon from '@material-ui/icons/Close';

import './SessionsTable.css';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#60aaff",
      color: theme.palette.common.white,
      fontSize:16,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const SessionsTable = ({icon, title, heads, sessions, show_duration=true}) => {
    // states
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [modalState, setModalState] = React.useState("close");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleModalState = (key) => {
        setModalState(key);
    }

    const handleModalClose = () => {
        setModalState("close");
    }

    // handling parameters
    const getHeaders = () => {
        var headers = [<StyledTableCell align="center">Session #</StyledTableCell>, <StyledTableCell align="center">Date</StyledTableCell>];
        heads.map(header => {
            headers.push(
                <StyledTableCell align="center">{header}</StyledTableCell>
            );
        });

        if(show_duration){
          headers.push(<StyledTableCell align="center">Duration</StyledTableCell>)
        }

        return headers;
    }

    const createSessions = () => {
        let s = [];

        sessions.map((session, index) => {
            
            const getDuration = () => {
                const sess_start = session["start"];
                const sess_end = session["end"];
                const hour_diff = sess_end.slice(12, 14) - sess_start.slice(12, 14);
                const min_diff = sess_end.slice(15, 17) - sess_start.slice(15, 17);
                const sec_diff = sess_end.slice(18, 20) - sess_start.slice(18, 20);
        
                var dur = "";
        
                if(hour_diff > 0){
                    dur += hour_diff.toString() + " h ";
                }
                if(min_diff > 0) {
                    dur += min_diff.toString() + " m ";
                }
                if (sec_diff > 0) {
                    dur += sec_diff.toString() + " s";
                }
        
                if (dur == ""){
                    dur = "0 s"
                }
                
                return dur;
            }

            let newRows = [];

            session["properties"].map(property => {
                newRows.push(
                    <StyledTableCell align="center">{property}</StyledTableCell>
                );
            });
            if(show_duration){
              let time = getDuration();
              newRows.push(<StyledTableCell align="center">{time}</StyledTableCell>)
            }


            s.push(
                <StyledTableRow key={index}>
                  <StyledTableCell  component="th" scope="row" align="center">{index + 1}</StyledTableCell>
                  <StyledTableCell align="center" className="gameNameCell">
                    {session["date"]}
                  </StyledTableCell>
                  {newRows}
                  
                </StyledTableRow>
            );
        });
        return s;
    }

    const tableHeaders = getHeaders();
    
    const sessionRows = createSessions();
    var sessionsList = [];
    if (sessionRows.length > 0) {
      sessionsList = sessionRows;
    } else {
      sessionsList.push(
        <TableCell colSpan={show_duration ? 3 + heads.length : 2 + heads.length} style={{textAlign:"center", backgroundColor:"#eee"}}>No sessions to show</TableCell>
      )
    }
    // const sessionsList = createSessions();
    const fullSessionsList = sessionsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return(
        <div>
            {/*session table*/}
        <div className="table-head-wrap">
          <div className="popup-subheader">{icon} <b>{title}</b></div>
          <div className="b-popup-view-button" onClick={() => handleModalState('sessions')}>View All</div>
        </div>

      <Table className="" aria-label="customized table">
        <TableHead>
          <TableRow>
                  {tableHeaders}
          </TableRow>
        </TableHead>
        <TableBody>
          {sessionsList.slice(-3).reverse()}
        </TableBody>
      </Table>
      {/*</div>*/}
      <Modal open={'sessions' === modalState}
                      onClose={handleModalClose}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                      style={{display:'flex',alignItems:'center',justifyContent:'center', outline: 0}}>
          <div className="sessions-popup">
            <div className="table-head-wrap">
              <div className="tableTitle"><AssessmentIcon id="icon"/> All Sessions</div>
              <div className="view-button" onClick={handleModalClose}><CloseIcon/></div>
            </div>
            <Table stickyHeader className="full-table" aria-label="customized table">
              <TableHead>
                <TableRow>
                  {tableHeaders}
                </TableRow>
              </TableHead>
              <TableBody>
                {fullSessionsList}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={sessionsList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              style={{backgroundColor: 'white'}}
            />
          </div>
      </Modal>
        </div>
    );
}

export default SessionsTable;