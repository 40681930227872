import React, {useContext} from 'react';

import './RhymeRacerTab.css';
import { GameContentContext } from '../../helpers/GameContentProvider';

import Badge from '@material-ui/core/Badge';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';

import SessionsTable from '../SessionsTable';
import QuestionsPopup from './QuestionsPopup';
import TopicPopup from './TopicPopup';
import CurriculumPopup from './CurriculumPopup';

const RhymeRacerTab = ({ data, dates }) => {
  const [modalState, setModalState] = React.useState("close");
  const { gameContent } = useContext(GameContentContext);

  const handleModalState = (key) => {
    setModalState(key);
  }

  const handleModalClose = () => {
    setModalState("close");
  }

  let rr_sessions = {};
  if (Object.keys(data).length > 0) {
    rr_sessions = data.RHYMERACER_PLAY_HISTORY.gameSessions;
  }

  const reformatSessions = () => {
    let new_sessions = [];
    let question_popups = [];
    let k = 0;
    for (const [i, s] of Object.entries(rr_sessions)){
      let words_seen = ""
      if (s.target_words_seen && s.target_words_seen[0] != "NULL"){
        words_seen = (s.target_words_seen).join(', ')
      } else {
        words_seen = "N/A"
      }

      let valid_date = ["N/A", "N/A"];
      if (dates && dates[k]) {
        valid_date = dates[k];
      }

      let accuracy = ((s.completed_success / Object.keys(s.rounds).length)*100);
      new_sessions.push(
        {
          "date": valid_date[0].toString().slice(0, 10),
          "start": valid_date[0],
          "end": valid_date[1],
          "properties":[words_seen == "N/A" ? words_seen : words_seen.toLowerCase(), <span className="view-text" onClick={() => handleModalState(i)}>View All</span>, `${accuracy}%`]
        }
      );
      question_popups.push(
        <QuestionsPopup open={i === modalState} handleClose={handleModalClose} questions={s.rounds ? s.rounds : []} accuracy={accuracy} index={k+1}/>
      );

      k += 1;
    }
    return [new_sessions, question_popups];
  }

  const createTopics = (s, mv) => {
    let rr_topics = [];
    let rr_topics_popups = [];

    let means_vars = mv;
    
    gameContent.rhymeracer.topics.map(topic => {
      let t_words = [];
        gameContent.rhymeracer.words.map(word => {
            if (word.topic == topic.item) {
                t_words.push(word.item)
            }
        });

      let opened = false;
      // mark if unopened since last update
      if(localStorage.getItem('rhymeracer') != null){
        let r_data = JSON.parse(localStorage.getItem('rhymeracer'));
        let uid = window.location.pathname.slice(1);
        let session_length = 0;
        t_words.map(word_key => {
          session_length += s[word_key].length;
        });
        if(session_length == 0 || (Object.keys(r_data).includes(uid) && r_data[uid][topic.item] && session_length == r_data[uid][topic.item])){
          opened = true;
        } 
      }

      rr_topics.push(
          <div className="topic-block" onClick={() => handleModalState(topic.item)}>
            <Badge color="secondary" variant="dot" invisible={opened}>
              <div className="topic-name">{topic.item}</div>
            </Badge>
            <div className="topic-count">{t_words.length} words</div>
          </div>
      );

      rr_topics_popups.push(
        <TopicPopup open={topic.item === modalState} handleClose={handleModalClose} topic={topic.item} words={t_words.sort()} sessions={s} m_v={means_vars}/>
      )
    });
    return [rr_topics, rr_topics_popups];
  }
    
  const findWordSessions = () => {
    let w_sessions = {};
    
    gameContent.rhymeracer.words.map(word => {
      w_sessions[word.item] = [];
    });

    for (let s of Object.values(rr_sessions)){
      for (let r of Object.values(s.rounds)){
        let wordset = r.selected_wordset;
        if (wordset && wordset[0] != "NULL") {
          let w = wordset[1].toLowerCase();
          w_sessions[w].push(r);
        }
      }
    }

    return w_sessions;
  }

  function getNotNull(list, node) {
    let i = list.length - 1;
    while (i > -1){
      if (list[i][node] && list[i][node][0] != "NULL"){
        return list[i];
      } else {
        i--;
      }
    }
    return false;
  }

  const getLastSession = () => {
    let means_vars = {};
    let curriculum = [];
    let last_sess = false;
    let last_round = false;
    if (Object.values(rr_sessions).length > 0){
      last_sess = getNotNull(Object.values(rr_sessions), "target_words_seen");
      if(last_sess.rounds){
        last_round = getNotNull(Object.values(last_sess.rounds), "post_means");
      }
    }
    if (last_sess && last_round){
      curriculum = last_sess.curriculum;
      curriculum.map((curr_word, index) => {
        means_vars[curr_word.toLowerCase()] = {mean: last_round.post_means[index], variance: last_round.post_vars[index]}
      });
    }
    return {mv: means_vars, curriculum: curriculum};
  }

  const created_sessions = reformatSessions();
  const sessionsList = created_sessions[0];
  const questions = created_sessions[1];

  let word_sessions = findWordSessions();
  let last_session = getLastSession();
  const created_topics = createTopics(word_sessions, last_session.mv);
  const topics = created_topics[0];
  const topics_popups = created_topics[1];

  return (
    <div>
      <div className="topics-wrap">
        <div className="topics-head-wrap">
          <div className="popup-subheader"><SpellcheckIcon id="icon"/> <b>Rhyme Topics</b></div>
          <div className="view-curriculum" onClick={() => handleModalState("curriculum")}>View Current Curriculum</div>
        </div>
        <div className="topics-contain">
          {topics}
        </div>
        {topics_popups}
      </div>

      <div className="table-wrap">
        <SessionsTable icon={<EqualizerIcon id="icon"/>}
          title="Sessions"
          heads={["Words Seen", "Questions", "Accuracy"]}
          sessions={sessionsList}
        />
        {questions}
      </div>

      <CurriculumPopup open={modalState === "curriculum"} handleClose={handleModalClose} curriculum={last_session.curriculum} rrModal={handleModalState}/>
    </div>
  );
};

export default RhymeRacerTab;
