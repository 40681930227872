import React, {useContext} from 'react';

import './StorybooksTab.css';
import BookPopup from "./BookPopup";
import Statistics from "./Statistics";
import { GameContentContext } from '../../helpers/GameContentProvider';

import Badge from '@material-ui/core/Badge';

const StorybooksTab = ({ data }) => {

  const [modalState, setModalState] = React.useState("close");
  const { gameContent } = useContext(GameContentContext);

  const handleModalState = (key) => {
    setModalState(key);
  }

  const handleModalClose = () => {
    setModalState("close");
  }

  console.log('Game contents');
  console.log(gameContent);

  // creates array of books
  const createBooks = () => {
    let bookList = [];

    // generate books
      for (let topic of gameContent.storybook.topics) {
        let book = {
          key: topic.item,
          name: topic.name,
          image: "",
          opened: false,
          count: 0,
          bookSessions:[],
          wordList:[],
          exposed:[],
          learned:[],
        };
        
        if (Object.keys(data).length > 0 && data.gameInfo && book.key in data.gameInfo) {
          book.count = data.gameInfo[book.key].read_count; // set times read
        }
  
        bookList.push(book);
      } // end for loop
  
      // let bookDivs = [];
      for (let [i, book] of bookList.entries()) {
        var image = "";
        try {
          image = require(`./assets/${book.key}.jpg`);
        } catch {
          image = "";
        }
  
        book.image = image;
  
          // get all sessions that involved the book
        for (let session in data.sessions){
          if (data.sessions[session].stories){
            let storiesData = data.sessions[session].stories;
            for (let story in storiesData){
              let individualStory = storiesData[story];
              if(individualStory.storyname == book.key){
                book.bookSessions.push(individualStory);
              }
            }
          }
        }
  
          // get all words of a book
        for (let word in gameContent.storybook.words) {
          // console.log(word)
          let individualWord = gameContent.storybook.words[word]
          if (individualWord.topic.includes(book.key)) {
            book.wordList.push(individualWord.item);
          }
        }

        // mark if unopened since last update to book
        if(localStorage.getItem('storybooks') != null){
          let s_data = JSON.parse(localStorage.getItem('storybooks'));
          let uid = window.location.pathname.slice(1);
          if(book.bookSessions.length == 0 || (Object.keys(s_data).includes(uid) && s_data[uid][book.key] && book.bookSessions.length == s_data[uid][book.key])){
            book.opened = true;
          } else {
            book.opened = false;
          }
        }
  
          // differentiate between learned and exposed words
        if (data.gameInfo) {
          let wordsList = false;
          if (book.key in data.gameInfo) {
            wordsList = data.gameInfo[book.key].words;
          }
          if (wordsList){
            for (let [key, value] of Object.entries(wordsList)){
              let word = value;
              if (word.result == true){
                book.learned.push(key);
              } else {
                book.exposed.push(key);
              } // end if statement
            } // end for loop
          }
        } // end of entire if statement
  
  
      }
    
    return bookList;
  }

  // creates html divs for books
  const generateBookDivs = (book_list) => {
    let bookDivs = [];
    let bookPopups = [];
    for (let bookIndex in book_list) {
      let book = book_list[bookIndex];
      bookDivs.push(
          <div className="book-wrap" onClick={() => handleModalState(book.key)}>
            {/* <div className="book-progress">
              {book.count}
            </div> */}
        <Badge color="secondary" variant="dot" invisible={book.opened}>
            <div className="book-img-wrap">
              <img src={book.image} className="book-img"/>
            </div>
        </Badge>
            <div className="book-title">{book.name}</div>
          </div>
      );
      bookPopups.push(
        <BookPopup name={book.name} image={book.image} open={book.key === modalState} handleClose={handleModalClose} book={book}/>
      )
    }
    return [bookDivs, bookPopups];
  }


  let book_data = createBooks();
  let created_books = generateBookDivs(book_data);
  let books = created_books[0];
  let popups = created_books[1];
  console.log('data within storybooks tab');
  console.log(data);

  return (
    <div>
      <Statistics data={data} books={book_data}/>
      <div className="book-container">
        {books}
        {popups}
      </div>
    </div>
  );
};

export default StorybooksTab;
