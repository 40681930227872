import React from 'react';

import './BookPopup.css';
import Modal from "@material-ui/core/Modal";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import EqualizerIcon from '@material-ui/icons/Equalizer';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SessionsTable from '../SessionsTable';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" style={{height:20, borderRadius:10, backgroundColor:'#ededed',}} {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


const BookPopup = ({name, image, open, handleClose, book}) => {

  // creates all word bubbles
  const createWordList = (wordList) => {
    let wordDivs = [];
    for (let wordIndex in wordList){
      let word = wordList[wordIndex]
      if (book.exposed.includes(word)) {
        wordDivs.push(
        <div className="word-bubble exposed">{word}</div>
      )
      } else if(book.learned.includes(word)) {
        wordDivs.push(
          <div className="word-bubble learned">{word}</div>
        )
      } else {
        wordDivs.push(
          <div className="word-bubble unexposed">{word}</div>
        )
      }
    }
    return wordDivs;
  }


  let totalDuration = 0; // in seconds
  // create table rows of sessions for table
  const createSession = (bookList) => {
      let sessionList = [];
      bookList.map(session => {
        const sess_start = session.start_reading_time;
        const sess_end = session.stop_reading_time;
        const hour_diff = sess_end.slice(12, 14) - sess_start.slice(12, 14);
        const min_diff = sess_end.slice(15, 17) - sess_start.slice(15, 17);
        const sec_diff = sess_end.slice(18, 20) - sess_start.slice(18, 20);

        function addIfPos(dur_num, factor=1) {
          if (dur_num > 0) {
            return (dur_num * factor)
          } else {
            return 0
          }
        }

        totalDuration += addIfPos(hour_diff, 3600)
        totalDuration += addIfPos(min_diff, 60)
        totalDuration += addIfPos(sec_diff)

        let keywords = ""
        if (session.keywords_asked){
          keywords = (session.keywords_asked).join(', ')
        } else {
          keywords = "N/A"
        }
        sessionList.push(
          {
            "date": session.start_reading_time.slice(0, 10),
            "start": session.start_reading_time,
            "end": session.stop_reading_time,
            "properties": [
              `${session.start_page} - ${session.stop_page}`, keywords 
              ]
          }
        );
      });
      return sessionList;
  }


  // calculate duration
  let splitDuration = [0, 0, 0];
  const setDuration = () => {
    let hours = Math.floor(totalDuration / 3600);
    let remainder = totalDuration % 3600;
    if (hours > 0) {
      splitDuration[0] = hours;
    }
    let minutes = Math.floor(remainder / 60);
    remainder = remainder % 60;
    if (minutes > 0) {
      splitDuration[1] = minutes;
    }
    splitDuration[2] = remainder;
  }

  const trackUpdates = (sList) => {
    let s_updates = {};
    if (localStorage.getItem("storybooks") != null) {
      console.log("someone else was here")
      s_updates = JSON.parse(localStorage.getItem("storybooks"));
    }
    let uid = window.location.pathname.slice(1);
    if(!Object.keys(s_updates).includes(uid)){
      s_updates[uid] = {};
    }
    s_updates[uid][book.key] = sList.length;
    localStorage.setItem("storybooks", JSON.stringify(s_updates));
  }

  const progressVal = (book.exposed.length / book.wordList.length) * 100
  const sessionsList = createSession(book.bookSessions);
  const words = createWordList(book.wordList.sort());

  setDuration();
  if(open){
    trackUpdates(sessionsList);
  }
  console.log(book);

  return(
    <Modal open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
      <div>
        <div className="popup-title">{name}</div>
        <div className="popup-block book-popup-block">

          <div className="popup-content">

            {/*left column*/}
            <div className="popup-left">
              <img src={image} className="book-img"/>
              <div className="book-stat-block">
                <div className="book-stat">{splitDuration[0]} h {splitDuration[1]} m {splitDuration[2]} s</div>
                <div className="book-stat-label">Total Time Read</div>
              </div>
              <div className="book-stat-block">
                <div className="book-stat">{book.count} times</div>
                <div className="book-stat-label">Opened</div>
              </div>

            </div>

            {/*right column*/}
            <div className="popup-right">
              <div className="popup-subheader"><TrendingUpIcon id="icon"/> <b>Progress</b></div>
              <div className="progress-bar-wrap"><LinearProgressWithLabel value={progressVal}/></div>

              {/*session table*/}
              <SessionsTable icon={<EqualizerIcon id="icon"/>}
                title="Sessions"
                heads={["Pages Read", "Keywords Asked"]}
                sessions={sessionsList}/>


              <div className="popup-subheader" style={{paddingTop:"1.25em"}}><SpellcheckIcon id="icon"/> <b>Vocabulary</b></div>
              <div className="chip-container">
                <Chip avatar={<Avatar style={{color:'white'}}>{book.learned.length}</Avatar>} label="Learned" style={{backgroundColor: '#0188fe', color: 'white'}}/> 
                <Chip avatar={<Avatar>{book.exposed.length}</Avatar>} label="Exposed" style={{backgroundColor: '#ffd895'}}/> 
                <Chip avatar={<Avatar>{book.wordList.length - book.learned.length - book.exposed.length}</Avatar>} label="Unexposed" />
              </div>
              <div className="word-box">{words}</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BookPopup;
