import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import './css/app.css';

import Routes from './Routes';
import StudentProvider from './helpers/StudentProvider';
import GameContentProvider from './helpers/GameContentProvider';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#60aaff',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#60aaff',
    },
    success: {
      main:'#5cb85c',
    },
  },
});

const App = () => {
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <StudentProvider>
          <GameContentProvider>
            <Routes />
          </GameContentProvider>
        </StudentProvider>
      </MuiThemeProvider>
    </div>
  );
};

export default App;
