import React, { useState } from 'react';
import Modal from "@material-ui/core/Modal";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';

import DoneIcon from '@material-ui/icons/Done';
import VisibilityIcon from '@material-ui/icons/Visibility';

import './ScenePopup.scss';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div className="scene-tab"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const ScenePopup = ({open, handleClose, name, data, progress, sessions}) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    function getByMissions(d){
        let divs = [];

        let seen = {};
        if(progress && progress.completed_quests){
            seen = progress.completed_quests.reduce(function(obj, b) {
                obj[b] = ++obj[b] || 1;
                return obj;
              }, {});
        }

        let words_seen = {};
        if(sessions.length > 0){
            for(let s of sessions){
                s.properties[2].split(", ").reduce(function(obj, b) {
                    obj[b] = ++obj[b] || 1;
                    return obj;
                }, words_seen);
            }
        }

        Object.keys(d).map(mission => {
            let word_list = [];
            d[mission].map(m => {
                word_list.push(
                    <Chip avatar={<Avatar>{words_seen[m] || 0}</Avatar>} label={m} 
                    // style={{
                    //     backgroundColor: words_seen[m] > 0 && '#60aaff',
                    //     color: words_seen[m] > 0 && '#ffffff',
                    // }}
                    />
                )
            })

            divs.push(
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                {mission} {seen && Object.keys(seen).includes(mission) && <div className="comp-count"><VisibilityIcon/> {seen[mission]}</div>}
                            </AccordionSummary>
                            <AccordionDetails>
                                {word_list}
                            </AccordionDetails>
                        </Accordion>
            )
        });
        return divs;
    }

    function getByWord(d){
        let divs = [];
        Object.keys(d).map(word => {
            let mission_list = [];
            d[word].map(w => {
                mission_list.push(
                    <Chip size="small" label={w} />
                )
            })
            divs.push(
                <div style={{marginBotton:'1em'}}>
                    {word}
                    <div>{mission_list}</div>
                </div>
            )
        });
        return divs;
    }

    function getAllWords(d){
        let all_words = [];
        Object.keys(d).map(w => {
            all_words.push(
                <Chip label={w} />
            )
        });
        return all_words;
    }

    function getAllMissions(d){
        let seen = [];
        if(progress){
            seen = progress.completed_quests;
        }
        let all_missions = [];
        Object.keys(d).map(m => {
            all_missions.push(
                <Chip label={m} 
                icon={seen && seen.includes(m) ? <DoneIcon/> : null}/>
            )
        });
        return all_missions;
    }

    // console.log(data);
    let by_missions = getByMissions(data.by_mission);
    let by_word = getByWord(data.by_word);
    let all_words = getAllWords(data.by_word);
    let all_missions = getAllMissions(data.by_mission);
    // let by_missions = "";

    return(
        <Modal open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div>
                <div className="popup-title-scene">{name}</div>
                <div className="popup-block-scene">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Sort by quests" {...a11yProps(0)} />
                        {/* <Tab label="Sort by words" {...a11yProps(1)} /> */}
                        <Tab label="All words" {...a11yProps(1)} />
                        <Tab label="All quests" {...a11yProps(2)} />
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        {by_missions}
                    </TabPanel>
                    {/* <TabPanel value={value} index={1}>
                        {by_word}
                    </TabPanel> */}
                    <TabPanel value={value} index={1}>
                        {all_words}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {all_missions}
                    </TabPanel>
                </div>
            </div>
        </Modal>
    )
}

export default ScenePopup;