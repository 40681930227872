import React, { useState } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { navigate, Redirect } from '@reach/router';

const endpoint = window.location.origin;

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      const response = await fetch(`${endpoint}/auth`, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify({ username, password }), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const json = await response.json();
      if (json.access_token) {
        console.log('token', json.access_token);
        localStorage.setItem('authToken', json.access_token);
        navigate('/dashboard');
      } else {
        setUsername('');
        setPassword('');
      }
    } catch (error) {
      console.error('Error:', error);
      // To-do: Create error state and display error message
      setUsername('');
      setPassword('');
    }
  };

  const classes = useStyles();

  const token = localStorage.getItem('authToken');

  if (token) {
    return <Redirect to="/dashboard" />;
  }

  if (!token) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              value={username}
              onChange={e => setUsername(e.target.value)}
              required
              fullWidth
              id="code"
              label="Authorization Code"
              name="code"
              autoComplete="code"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyDown={e => {
                if (e.keyCode === 13) handleLogin();
              }}
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => {
                handleLogin();
              }}
            >
              Sign In
            </Button>
          </form>
        </div>
      </Container>
    );
  }
};

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default Login;
