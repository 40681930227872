import React, { useState, createContext, useMemo } from 'react';

export const StudentContext = createContext({});

const StudentProvider = ({ children }) => {
  const [selectedStudent, setSelectedStudent] = useState({});

  const studentProviderValue = useMemo(() => ({ selectedStudent, setSelectedStudent }), [
    selectedStudent,
    setSelectedStudent,
  ]);

  return <StudentContext.Provider value={studentProviderValue}>{children}</StudentContext.Provider>;
};

export default StudentProvider;

