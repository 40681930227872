import React, { useState } from 'react';
import { Link } from '@reach/router';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import { ArrowForward } from '@material-ui/icons';

import './StudentCard.css';

import defaultImage from '../../Home/assets/c001.png';

const StudentCard = ({ info, image }) => {
  let color;
  switch (info.priority) {
    case 0:
      color = '#91ff82';
      break;
    case 1:
      color = '#FFFD8E';
      break;
    default:
      color = '#ffa395';
  }

  let today = new Date();
  let todayStr = today.toISOString().slice(0,10).replace(/-/g,"/");
  let thisMonth =  todayStr.slice(5, 7);
  let thisYear = todayStr.slice(0, 4);
  let monthCount = 0;
  for (let sessionIndex in info.sessions.reverse()){
    let sess_start = info.sessions[sessionIndex].session_start.slice(0, 10);
    if (sess_start.slice(0, 2) == thisMonth && sess_start.slice(6, 10) == thisYear){
      monthCount += 1;
    } else {
      break;
    }
  }

  let hasNoUpdate = false;
  if(localStorage.getItem('students') != null){
    let s_data = JSON.parse(localStorage.getItem('students'));
    let uid = info.id;
    if(info.sessions.length == 0 || (Object.keys(s_data).includes(uid) && s_data[uid] && info.sessions.length == s_data[uid])){
      hasNoUpdate = true;
    }
  }

  return (
          <div className="card">
            <div className="name">

              {/*image*/}
              <div className="profile-pic-wrapper">
                {image ? (
                <img src={image} className="profile-pic" alt="profile pic" />
              ) : (
                <img src={defaultImage} className="profile-pic" alt="profile pic" />
              )}
              </div>

              {/*name*/}
              <Badge color="secondary" variant="dot" invisible={hasNoUpdate}>
              <div>{info.name}</div>
              </Badge>
            </div>

            {/*stats of sessions*/}
            <div className="stats-wrap">
              <div className="stat-block">
                <span className="number">{monthCount}</span>
                <br/><span className="subtitle">this month</span>
              </div>
              <div className="stat-block">
                <span className="number">{info.sessions.length}</span>
                <br/><span className="subtitle">overall</span>
              </div>
            </div>

            {/*button*/}
            <Link to={`/${info.id}`} className="link">
               <Button variant="contained" size="small" id="button">
                 Progress
                 <ArrowForward />
               </Button>
            </Link>
          </div>
  );
};

export default StudentCard;
