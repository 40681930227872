import React, { useState, useEffect, useContext } from 'react';
import { Input, InputAdornment, Grid } from '@material-ui/core';
import { Search, ExitToApp } from '@material-ui/icons';
import socketIOClient from 'socket.io-client';
import { Redirect } from '@reach/router';

import StudentCard from './components/StudentCard';
import { StudentContext } from '../../helpers/StudentProvider';

import './Home.css';

const endpoint = window.location.origin;

const status = {
  CORRECT: 0,
  INCORRECT: 1,
  UNKNOWN: 2,
};

const Home = () => {
  const [studentInfo, setStudentInfo] = useState([]);
  const [filter, setFilter] = useState('');

  const { setSelectedStudent } = useContext(StudentContext);

  useEffect(() => {
    getSession();
    getStudentInfo();
  }, []);

  useEffect(() => {
    return () => {
      console.log('Cleaned up');
    };
  }, []);

  const socket = socketIOClient(endpoint);

  const sortData = (data) => {
    data.sort(function (a, b) {
      return a.sessions.length - b.sessions.length;
    });
    const prioritized_data = data.map((studentInfo) => {
      const newData = { ...studentInfo };
      if (studentInfo.sessions.length > 4) {
        newData.priority = 0;
      } else {
        newData.priority = 2;
      }
      return newData;
    });
    return prioritized_data;
  };

  const updateStudents = (newStudents) => {
    const data = newStudents.map((student) => {
      try {
        student.image = require(`./assets/${student.id}.png`);
      } catch (e) {}
      return student;
    });
    setStudentInfo(sortData(data));
  };

  const updateOne = (d) => {
    d.studentInfo.id = d.subject_id;
    try {
      // Note: **This is a relative path to where it's being call! Change as needed**
      d.studentInfo.image = require(`../Home/assets/${d.studentInfo.id}.png`);
    } catch (e) {}
    // assume unique ids
    const changeable = this.state.studentInfo.filter((student) => {
      return student.id === d.subject_id;
    });
    const newData = this.state.studentInfo.filter((student) => {
      return student.id !== d.subject_id;
    });
    newData.push(d.studentInfo);
    setStudentInfo(sortData(newData));
  };

  const getStudentInfo = () => {
    getData();
    socket.on('student_info', (data) => {
      updateOne(data);
    });
  };

  const getSession = async () => {
    try {
      const response = await fetch(`${endpoint}/whoami`, {
        method: 'GET', // or 'PUT'
        headers: {
          Accept: 'application/json',
          Authorization: `JWT ${localStorage.getItem('authToken')}`,
        },
      });
      const r = await response.json();
      if (r.username) {
        socket.emit('join', { user: r.username });
      } else {
        // Redirect back to login if username does not exist ? - Gary
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getData = async () => {
    try {
      const response = await fetch(`${endpoint}/get_students`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `JWT ${localStorage.getItem('authToken')}`,
        },
      });
      const json = await response.json();
      updateStudents(json);
    } catch (error) {
      console.error('Error:', error);
    }

  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setStudentInfo([]);
    setFilter('');
  };

  // for search functionality
  let filteredStudents = studentInfo;
  if (filter) {
    filteredStudents = filteredStudents.filter((stud) => {
      return stud.name.toLowerCase().startsWith(filter.toLowerCase());
    });
  }

  const studentContainers = filteredStudents.map((studentInfo, index) => {
    return (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <div
          onClick={() => {
            setSelectedStudent(studentInfo);
          }}
          key={index}
          className="student-card-wrapper"
        >
          <StudentCard image={studentInfo.image} info={studentInfo} />
        </div>
      </Grid>
    );
  });

  const token = localStorage.getItem('authToken');

  if (!token) {
    return <Redirect to="/" noThrow />;
  }
  if (token) {
    return (
      <div className="container">
        <div id="nav">
          <div className="left-wrap">
            <div className="page-name"><a href="/dashboard">Dashboard</a></div>
            <div id="search">
            <Input
                    startAdornment={
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    }
                    onChange={(e) => {
                      setFilter(e.target.value);
                    }}
                    style={{ padding: 0 }}
                    placeholder="Search"
                    disableUnderline
                  />
          </div>
          </div>
          <div className="right-wrap">
            <ExitToApp onClick={() => logout()} id="exit-button" />
          </div>
        </div>

        <div className="container-of-students">
            {studentContainers}
        </div>
      </div>
    );
  }
};

export default Home;
