import React from 'react';
import Modal from "@material-ui/core/Modal";

import './QuestionsPopup.css';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TimerIcon from '@material-ui/icons/Timer';
import { green } from '@material-ui/core/colors';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      overflowY:'auto',
      overflowX:'hidden',
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  }));

const QuestionsPopup = ({open, handleClose, questions, index}) => {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getTabPanels = () => {
        let tab_panels = [];

        if (questions){
          let i = 1;
          for (let round of Object.values(questions)){
            let word_choices = [];
            word_choices.push(
              <div className="prompt">
                <div>What rhymes with <span style={{textTransform:'lowercase'}}>{round.selected_wordset[0]}</span>?</div>
                <div className="time"><TimerIcon/> {round.player_time_to_tap == -1 ? "Passed time limit" : "Answered in: " + parseFloat(round.player_time_to_tap).toFixed(2) + " s"}</div>
              </div>
            )
            const letters = ["A", "B", "C", "D"]
            const words_to_choose = round.selected_wordset.slice(1)
            words_to_choose.map(word => {
              if (round.selected_wordset[1] == word) {
                if (round.player_selection == word) {
                  word_choices.push(
                    <div className="choice correct s-correct">
                      <div className="choice-icon"><CheckCircleIcon style={{ color: green[500] }}/></div> {word}
                    </div>
                  )
                } else {
                  word_choices.push(
                    <div className="choice correct">
                      <div className="choice-icon"><div className="choice-letter">{letters[words_to_choose.indexOf(word)]}</div></div>{word}
                    </div>
                  )
                }
              } else if(round.player_selection == word) {
                word_choices.push(
                  <div className="choice s-incorrect">
                    <div className="choice-icon"><HighlightOffIcon color="error"/></div> {word}
                  </div>
                )
              } else {
                word_choices.push(
                  <div className="choice">
                    <div className="choice-icon"><div className="choice-letter">{letters[words_to_choose.indexOf(word)]}</div></div>{word}
                    {/* <div className="choice-letter">{letters[words_to_choose.indexOf(word)]}</div> {word} */}
                  </div>
                )
              }
              
            })

            tab_panels.push(
              <TabPanel value={value} index={i - 1}>
                <div className="questions-wrap">
                  {word_choices}
                </div>
              </TabPanel>
            )
            i += 1;
          }
        }
        
        return tab_panels;
    }

    const generateTabs = () => {
      let tabs = [];
      if (questions) {
        let i = 1;
        for (let round of Object.values(questions)){
          tabs.push(
            <Tab className="custom-tab" icon={round.player_correct ? <CheckCircleIcon style={{ color: green[500] }}/> : <CancelIcon color="error"/>}label={`Question ${i}`} {...a11yProps(i - 1)} />
          )
          i += 1;
        }
      }
      return tabs;
    }

    // const wordset = getWordset();
    const tabList = generateTabs();
    const panels = getTabPanels();

    return(
        <Modal open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div>
              <div className="popup-title">Session {index}</div>
            <div className="popup-block-q">
              <div className={classes.root}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="questions"
                className={classes.tabs}
              >
                {tabList}
              </Tabs>
              {panels}
              </div>
            </div>
            </div>
            
        </Modal>
    )

}

export default QuestionsPopup;