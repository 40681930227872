import React, { useState } from 'react';
import Modal from "@material-ui/core/Modal";

import './TopicPopup.css';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';

import LearnMorePopup from './LearnMorePopup';
import MasteryGraph from './MasteryGraph';

const TopicPopup = ({open, handleClose, topic, words, sessions, m_v}) => {
    const [modalState, setModalState] = useState("close");
    var overallMean = 0;
    var overallVar = 0;
    var correctAns = 0;
    var totalQ = 0;

    const handleModalState = (key) => {
        setModalState(key);
    }
    
    const handleModalClose = () => {
        setModalState("close");
    }

    const getWords = () => {
        let topic_words = [];

        words.map(word => {
            let num_correct = 0;
            let avg_time = 0;
            for(let s of sessions[word]){
                if (s.player_correct){
                    num_correct += 1;
                    avg_time += s.player_time_to_tap;
                }
            }

            let total_q = sessions[word].length;
            let num_accuracy = num_correct / total_q;
            avg_time /= total_q;

            let word_stats = {
                "correct answers": num_correct,
                "total questions": total_q,
                "overall accuracy": isNaN(num_accuracy) ? "-" : (num_accuracy*100).toFixed(2) + "%",
                "average time to answer correctly": isNaN(avg_time) || avg_time == 0 ? "-" : avg_time.toFixed(2) + " s",
            };

            correctAns += num_correct;
            totalQ += total_q;
            
            let stat_divs = [];

            for (let [label, stat] of Object.entries(word_stats)){
                stat_divs.push(
                    <div className="stat-contain">
                        <div className="stat-num">{stat}</div>
                        <div className="stat-head">{label}</div>
                    </div>
                )
            }

            let mean = 0;
            let variance = 0.2;
            if (Object.keys(m_v).length > 0 && Object.keys(m_v).includes(word)){
                mean = m_v[word].mean
                variance = m_v[word].variance
            }

            overallMean += mean;
            overallVar += variance;

            if(!Object.keys(m_v).includes(word)){
                topic_words.push(
                    <Accordion disabled>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                                <div>{word}</div>
                                <div>Not in curriculum</div>
                            </div>
                        </AccordionSummary>
                    </Accordion>
                )
            } else {
                topic_words.push(
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                {word}
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="stats-contain">
                                    {stat_divs}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                )
            }
        });
        return topic_words;
    }

    let word_divs = getWords();

    const trackUpdates = () => {
        let r_updates = {};
        if (localStorage.getItem("rhymeracer") != null) {
          r_updates = JSON.parse(localStorage.getItem("rhymeracer"));
        }
        let uid = window.location.pathname.slice(1);
        if(!Object.keys(r_updates).includes(uid)){
            r_updates[uid] = {};
        }
        r_updates[uid][topic] = totalQ;
        localStorage.setItem("rhymeracer", JSON.stringify(r_updates));
    }

    if (open){
        trackUpdates();
    }

    return(
        <Modal open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div>
                <div className="popup-title">{topic}</div>
              <div className="popup-block-topic">
                  
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <b>Overview</b>
                    </AccordionSummary>
                    <AccordionDetails>
                        
                    <div className="stats-contain">
                        <div style={{display:'flex', flexDirection:'column', flex:2}}>
                        <div className="stat-contain">
                            <div className="stat-num">{correctAns}</div>
                            <div className="stat-head">correct answers</div>
                        </div>
                        <div className="stat-contain">
                            <div className="stat-num">{totalQ}</div>
                            <div className="stat-head">total questions</div>
                        </div>
                        </div>
                        <div className="stat-contain" style={{flex:2}}>
                                <div className="stat-title">
                                    <div className="title-text">Estimated Overall Mastery</div>
                                        <Tooltip arrow title="Learn more" placement="top">
                                            <HelpIcon onClick={() => handleModalState("learnmore")}/>
                                        </Tooltip>
                                </div>
                                <MasteryGraph mean={overallMean / word_divs.length} variance={overallVar / word_divs.length}/>
                        </div>
                    </div>
                    </AccordionDetails>
                </Accordion>
                <br/>
                  {word_divs}
              </div>

                <LearnMorePopup open={modalState === "learnmore"} handleClose={handleModalClose}/>
            </div>
            
        </Modal>
    )
}

export default TopicPopup;