import React from 'react';
import { Chart, ChartAxis, ChartGroup, ChartLine } from '@patternfly/react-charts';

const MasteryGraph = ({mean, variance}) => {
    let st_dev = Math.sqrt(variance)
    let coords = [];
    for (var x = -2; x < 2.05; x += 0.05){
        let calc_y = (1/(st_dev*Math.sqrt(2*Math.PI)))*(Math.exp(-1*(Math.pow(x-mean,2)) / (2*Math.pow(st_dev, 2))))
        coords.push({x: x, y: calc_y});
    }
    
    return(
        <Chart 
            ariaTitle="Estimated Mastery" ariaDesc="A different view of current mastery of a word"
            height={250}
            minDomain={{y: 0}} maxDomain={{y: 2.5}}
            padding={{ bottom: 25, left: 25, right: 25, top: 15, }} 
        >
            <ChartAxis tickValues={[-1, 0, 1]} />
            <ChartAxis dependentAxis showGrid tickValues={[0]} />
            <ChartGroup>
                <ChartLine data={coords} interpolation="natural" style={{data: {stroke: "#60aaff"},}}/>
            </ChartGroup>
        </Chart>
    )
}

export default MasteryGraph;