import React, { useContext } from 'react';

import Modal from "@material-ui/core/Modal";
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MasteryGraph from './MasteryGraph';

const tutorial_steps = [
    {
        title: "Overview",
        mean: 0,
        var: 0.2,
        desc: "The estimated mastery graph gives a unique and detailed view concerning how well the student has mastered the rhyme. Direct data gathered about one word will help the model indirectly estimate other words' mastery levels. Before logging any data, however, the graph will look like the one on the right."
    },
    {
        title: "Direct Responses",
        mean: 0.74,
        var: 0.04,
        desc: "As a student directly responds to words, more data will be gathered. Direct responses will create more spiked graphs, as seen to the right. The farther the spike is to the right, the better the word has been mastered."
    },
    {
        title: "Uncertainty",
        mean: 0.3,
        var: 0.85,
        desc: "Flatter graphs indicate uncertainty as it primarily relies on indirect data. It's difficult to tell if the student has mastered the rhyme due to a lack of direct data. The graph on the right shows a rhyme topic that is both uncertain and less mastered, as the peak is closer to the center."
    }
];

const LearnMorePopup = ({open, handleClose}) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorial_steps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    return(
        <Modal open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div>
                <div className="popup-title-learnmore"><HelpOutlineIcon id="icon"/> Learn More</div>
                <div className="popup-block-learnmore">
                    {/* <div className="tutorial-title">{tutorial_steps[activeStep].title}</div> */}
                    <div className="tutorial-wrap">
                        <div className="graph-desc-wrap">
                            {tutorial_steps[activeStep].desc}
                        </div>
                        <div className="graph-wrap">
                            <MasteryGraph mean={tutorial_steps[activeStep].mean} variance={tutorial_steps[activeStep].var}/>
                        </div>
                    </div>
                </div>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="dots"
                    activeStep={activeStep}
                    nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        Next <KeyboardArrowRight />
                    </Button>
                    }
                    backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        <KeyboardArrowLeft />
                        Back
                    </Button>
                    }
                />
            </div>
        </Modal>
    )
}

export default LearnMorePopup;