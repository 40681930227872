import React from 'react';

import AccessTimeIcon from '@material-ui/icons/AccessTime';

import Donut from './Donut';

import './OverviewTab.css';
import SessionsTable from '../SessionsTable';

const OverviewTab = ({info}) => {

  let totalDuration = 0; // in seconds

  const createSessions = (sessions) => {
    let listOfSessions = [];
    if (sessions) {
      sessions.map(session => {
        const sess_start = session.session_start;
        const sess_end = session.session_end;
        const hour_diff = sess_end.slice(12, 14) - sess_start.slice(12, 14);
        const min_diff = sess_end.slice(15, 17) - sess_start.slice(15, 17);
        const sec_diff = sess_end.slice(18, 20) - sess_start.slice(18, 20);

        function addIfPos(dur_num, factor=1) {
          if (dur_num > 0) {
            return (dur_num * factor)
          } else {
            return 0
          }
        }

        totalDuration += addIfPos(hour_diff, 3600)
        totalDuration += addIfPos(min_diff, 60)
        totalDuration += addIfPos(sec_diff)

        listOfSessions.push(
          {
            "date": sess_start.slice(0, 10),
            "start": sess_start,
            "end": sess_end,
            "properties": [
              `${session.activity_type === "rhymeracer" ? "Rhyme Racer" : session.activity_type.charAt(0).toUpperCase() + session.activity_type.slice(1)}`
              ]
          }
        );
      });
    }
    return listOfSessions;
  }

  const sessionsList = createSessions(info.sessions);

  let splitDuration = [0, 0, 0];

  const setDuration = () => {
    let hours = Math.floor(totalDuration / 3600);
    let remainder = totalDuration % 3600;
    if (hours > 0) {
      splitDuration[0] = hours;
    }
    let minutes = Math.floor(remainder / 60);
    remainder = remainder % 60;
    if (minutes > 0) {
      splitDuration[1] = minutes;
    }
    splitDuration[2] = remainder;
  }

  setDuration();

  return(
    <div>
      <div className="top-container">
        {/*donut graph*/}
        <div className="cont-donut">
          <Donut info={info}/>
        </div>

        {/*duration count*/}
        <div className="cont-duration">
          <div>
            <span className="durTitle">Total Time Spent</span><br/>
          {splitDuration[0]} h {splitDuration[1]} m {splitDuration[2]} s
          </div>
        </div>
      </div>

        {/*sessions table*/}
      <div className="cont-table">
      <SessionsTable icon={<AccessTimeIcon id="icon"/>} 
          title="Most Recent"
          heads={["Game Played"]}
          sessions={sessionsList}
        />
      </div>
    </div>
  )
}

export default OverviewTab;
