import React from 'react';
import { Router } from '@reach/router';

import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import StudentProfile from './pages/StudentProfile/StudentProfile';
import TabsTest from './pages/TabsTest';

const Routes = () => (
  <Router>
    <Home path="/dashboard" />
    <Login path="/" />
    <StudentProfile path="/:studentID" />
    <TabsTest path="/tabstest" />
  </Router>
);

export default Routes;
