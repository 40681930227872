import React, { useContext } from 'react';

import { GameContentContext } from '../../helpers/GameContentProvider';

import Modal from "@material-ui/core/Modal";
import Chip from '@material-ui/core/Chip';

import SchoolIcon from '@material-ui/icons/School';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';

const CurriculumPopup = ({open, handleClose, curriculum, rrModal}) => {
    const { gameContent } = useContext(GameContentContext);

    const redirectToTopic = (topic) => {
        console.log(topic)
        rrModal(topic);
    }

    const getWords = () => {
        let word_list = [];
        if(curriculum.length > 0){
            // let word_to_topic = {};
            // console.log(curriculum);
            // for(let w of gameContent.rhymeracer.words){
            //     word = w.item.toString().toUppercase();
            //     if(curriculum.includes(word)){
            //         word_to_topic[w.item] = w.topic;
            //     }
            // }
            // console.log(word_to_topic);
            curriculum.map(word => {
                let topic = "";
                for(let w of gameContent.rhymeracer.words){
                    let l_word = String(word).toLowerCase();
                    if(w.item == l_word){
                        topic = w.topic;
                        break;
                    }
                }
                word_list.push(
                    <Chip label={word} style={{textTransform:'lowercase', marginRight:'0.5em', marginBottom:'0.5em'}} onClick={() => redirectToTopic(topic)}/>
                )
            });
        } else {
            word_list.push("No curriculum set")
        }

        return word_list;
    }

    let words = getWords();

    return(
        <Modal open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div>
                <div className="popup-title-learnmore"><SchoolIcon id="icon"/> Curriculum</div>
                <div className="popup-block-learnmore" style={{flexWrap:'wrap'}}>
                    {/* <div className="sort-button" onClick={() => curriculum.sort()}><SortByAlphaIcon id="icon"/> Sort Alphabetically</div> */}
                    {words}
                </div>
            </div>
        </Modal>
    )
}

export default CurriculumPopup;