import React, { useState, useContext, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { Close, Dashboard, LibraryBooks, Speed, Search } from '@material-ui/icons';
import { Link, Redirect } from '@reach/router';

import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import { StudentContext } from '../../helpers/StudentProvider';
import { GameContentContext } from '../../helpers/GameContentProvider';
import OverviewTab from '../../components/OverviewTab/OverviewTab';
import StorybooksTab from '../../components/StorybooksTab/StorybooksTab';
import WordquestTab from '../../components/WordquestTab/WordquestTab';

import './StudentProfile.css';
import RhymeRacerTab from '../../components/RhymeRacerTab/RhymeRacerTab';

const endpoint = window.location.origin;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function StudentProfile(props) {
  const { selectedStudent, setSelectedStudent } = useContext(StudentContext);
  const { setGameContent } = useContext(GameContentContext);
  const [storybookData, setStorybookData] = useState({});
  const [rhymeRacerData, setRhymeRacerData] = useState({});
  const [wordquestData, setWordquestData] = useState({});

  async function getGameContent() {
    try {
      const response = await fetch(`${endpoint}/get_game_contents`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `JWT ${localStorage.getItem('authToken')}`,
        },
      });
      const json = await response.json();
      setGameContent(json);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function getStudentData() {
    try {
      const response = await fetch(`${endpoint}/get_students`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `JWT ${localStorage.getItem('authToken')}`,
        },
      });
      const json = await response.json();
      var data = {};
      for (let stud in json) {
        if (json[stud]["id"] == props.studentID) {
          data = json[stud];
          break;
        }
      }
      if (data) {
        setSelectedStudent(data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function getGameData(url, set_func) {
    try {
      const response = await fetch(`${endpoint}/${url}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `JWT ${localStorage.getItem('authToken')}`,
        },
      });
      const json = await response.json();
      let s_data = json[props.studentID];
      if (s_data) {
        set_func(s_data);
      }
      console.log("finished getting data from " + url);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getStudentData();
      getGameData('get_storybooks', setStorybookData);
      getGameData('get_rhymeracer', setRhymeRacerData);
      getGameData('get_wordquest', setWordquestData);
      getGameContent();
    }

    return () => mounted = false;
  }, [])

  const token = localStorage.getItem('authToken');

  var profile_image = "";
    if (selectedStudent && selectedStudent.image) {
      profile_image = selectedStudent.image;
    } else {
      try {
        profile_image = require(`../Home/assets/${props.studentID}.png`);
      } catch {
        profile_image = require(`./default.jpg`);
      }
    }

  var rhymeracer_session_dates = [];
  if (selectedStudent && selectedStudent.sessions) {
    for (let session of selectedStudent.sessions) {
      if (session.activity_type == "rhymeracer") {
        rhymeracer_session_dates.push([session.session_start, session.session_end]);
      }
    }
  }

  if (!token) {
    return <Redirect to="/" />;
  }

  if (token) {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const trackUpdates = (sList) => {
      let s_updates = {};
      if (localStorage.getItem("students") != null) {
        s_updates = JSON.parse(localStorage.getItem("students"));
      }
      let uid = window.location.pathname.slice(1);
      if(selectedStudent && selectedStudent.sessions){
        s_updates[uid] = selectedStudent.sessions.length;
      }
      localStorage.setItem("students", JSON.stringify(s_updates));
    }

    trackUpdates();

    return (
      <div>
        <AppBar id="appBar">
             <Toolbar>
               <Link to="/dashboard" className="link">
                 <IconButton edge="start" color="inherit" aria-label="close">
                   <Close />
                 </IconButton>
               </Link>
               <Typography variant="h6">Progress Details</Typography>
             </Toolbar>
           </AppBar>

        <div className="contain">
          <div className="inner-contain-wrap">
            <div className="sidebar">
                <div className="profilePic">
                  <img src={profile_image}
                      alt="profile-pic"
                  />
                </div>
                <div className="nameContainer">
                  <div className="studentName">
                    {selectedStudent && selectedStudent.name}
                  </div>
                </div>

                <Tabs
                  orientation="vertical"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  id="tabs"
                >
                  <Tab icon={<Dashboard />}
                    label="Overview" {...a11yProps(0)} />
                  <Tab icon={<LibraryBooks />}
                    label="Storybooks" {...a11yProps(1)}/>
                  <Tab icon={<Speed />}
                    label="Rhyme Racer" {...a11yProps(2)} />
                  <Tab icon={<Search />}
                    label="Wordquest" {...a11yProps(3)} />
                </Tabs>

            </div>
            <div className="tab-content-wrap">
              <TabPanel value={value} index={0}>
                <OverviewTab info={selectedStudent}/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <StorybooksTab data={storybookData}/>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <RhymeRacerTab data={rhymeRacerData} dates={rhymeracer_session_dates}/>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <WordquestTab data={wordquestData}/>
              </TabPanel>
            </div>

          </div>
          
        </div>

      </div>
    );
  }
};

export default StudentProfile;
