import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import './tabstest.css';

const books = ['Book Title 1', 'Book Title 2', 'Book Title 3', 'Book Title 4', 'Book Title 5'];
const words = ['Word', 'Word', 'Word', 'Word', 'Word', 'Word', 'Word', 'Word', 'Word', 'Word'];

const TabsTest = () => {
  const [selectedBook, setSelectedBook] = useState(0);

  const handleBookTitleClick = (titleNumber) => {
    setSelectedBook(titleNumber);
  };

  return (
    <Tabs>
      <TabList>
        <Tab style={{ fontSize: '1.5rem' }}>Storybooks</Tab>
        <Tab style={{ fontSize: '1.5rem' }}>Wordquest</Tab>
      </TabList>

      <TabPanel style={{ backgroundColor: '#E8E8E8' }}>
        <div className="content-wrapper">
          <div className="content-panel">
            {books.map((title, index) => (
              <div className="title" key={index} onClick={() => handleBookTitleClick(index + 1)}>
                {title}
              </div>
            ))}
          </div>
          <div className="content-panel">Right panel</div>
        </div>
      </TabPanel>
      <TabPanel>
        <h2>Any content 2</h2>
      </TabPanel>
    </Tabs>
  );
};

export default TabsTest;
