import React from 'react';
import { ChartDonut, ChartThemeColor, ChartThemeVariant } from '@patternfly/react-charts';

import './Donut.css';

const Donut = ({info}) => {
    let colors = ['#95c6ff', '#ffd895', '#ffa395']; // colors for each section
    let counts = [0, 0, 0]; // storybook, rhymeracer, wordquest
    let total = 0; // total sessions
    if (info.sessions) {
      total = info.sessions.length;
      
      // count each type of session
      for (let session of info.sessions) {
        if (session.activity_type) {
          switch (session.activity_type) {
            case 'storybook':
              counts[0]++;
              break;
            case 'rhymeracer':
              counts[1]++;
              break;
            case 'wordquest':
              counts[2]++;
              break;
          }
        }
      }
    }

    

    // calculate percentages
    let percentages = {
      'Storybook': ((counts[0]/total)*100).toFixed(2),
      'Rhyme Racer': ((counts[1]/total)*100).toFixed(2),
      'Wordquest': ((counts[2]/total)*100).toFixed(2),
    }

    return (
  <div style={{ height: '230px', width: '350px' }}>
    <ChartDonut
      ariaDesc="Type of Games Played"
      ariaTitle="Sessions"
      colorScale={colors}
      constrainToVisibleArea={true}
      data={[{ x: 'Storybook', y: counts[0] }, { x: 'Rhyme Racer', y: counts[1] }, { x: 'Wordquest', y: counts[2] }]}
      innerRadius="75"
      labels={({ datum }) => `${datum.x}: ${percentages[datum.x]}%`}
      legendData={[{ name: `Storybook: ${counts[0]}`, symbol: {fill: colors[0]} },
                  { name: `Rhyme Racer: ${counts[1]}`, symbol: {fill: colors[1]} },
                  { name: `Wordquest: ${counts[2]}`, symbol: {fill: colors[2]} }]}
      legendOrientation="vertical"
      legendPosition="right"
      padding={{
        bottom: 20,
        left: 0,
        right: 140, // Adjusted to accommodate legend
        top: 20
      }}
      subTitle="sessions"
      title={total}
      width={350}
    />
  </div>
)
}


export default Donut;
